<template>
  <div>
    <b-jumbotron class="py-4 px-0">
      <b-container fluid>
      <h4>Setări</h4>
      </b-container>
    </b-jumbotron>
      <b-container fluid>
        <b-row>
          <b-col>
            <b-tabs pills active-tab-class="mt-4" active-nav-item-class="bg-dark" nav-wrapper-class="nav-border">
              <b-tab title="General"  active style="max-width: 800px">
                  <h6>Setări generale </h6>
                  <p>Personalizare nume companie, date de contact, logo și alte.</p>
                  <hr />
                    <b-form-group label-cols="4" label-cols-lg="2" label="Nume companie:" label-for="input-default">
                      <b-form-input v-model="company.name" ></b-form-input>
                    </b-form-group>
                    <b-form-group label-cols="4" label-cols-lg="2" label="Despre:" label-for="input-default">
                      <b-form-textarea
                        id="textarea"
                        v-model="company.description"
                        placeholder="Descrie compania ta în câteva cuvinte ..."
                        rows="3"
                        max-rows="6"
                      ></b-form-textarea>
                    </b-form-group>
                    <b-form-group label-cols="4" label-cols-lg="2" label="Logo:" label-for="input-default">
                      <template #description>
                        <img id="company-logo" :src="company.logo" height="30" alt="Logo companie">
                      </template>
                      <b-form-file  @change="uploadLogo" accept="image/*" v-model="company.logo" placeholder="Format: png, jpg, svg și webp" browse-text="Încarcă">
                      </b-form-file>

                    </b-form-group>
                    <b-form-group label-cols="4" label-cols-lg="2" label="Adresa email:" label-for="input-default">
                      <b-form-input :value="this.$store.getters['auth/loggedUser'].email" disabled></b-form-input>
                    </b-form-group>
                    <b-form-group label-cols="4" label-cols-lg="2" label="Telefon:" label-for="input-default">
                      <b-form-input :value="this.$store.getters['auth/loggedUser'].phone" disabled></b-form-input>
                    </b-form-group>
                    <b-form-group label-cols="4" label-cols-lg="2" label="Adresa:" label-for="input-default">
                      <b-form-input v-model="company.address"></b-form-input>
                    </b-form-group>

                    <b-button @click="updateCompany" class="pull-right ml-2 " size="sm" variant="success">Salvează</b-button>
              </b-tab>
              <b-tab title="Facturare" style="max-width: 800px">
                  <h6>Setări facturare</h6>
                  <p>Date firmă, CUI ...</p>
                  <hr />
                    <b-form-group label-cols="4" label-cols-lg="2" label="Nume companie:" label-for="input-default">
                      <b-form-input v-model="company.name" placeholder="Nume SRL"></b-form-input>
                    </b-form-group>
                    <b-form-group label-cols="4" label-cols-lg="2" label="CUI:" label-for="input-default">
                      <b-form-input v-model="company.euid" placeholder="RO12345678"></b-form-input>
                    </b-form-group>
                    <b-form-group label-cols="4" label-cols-lg="2" label="Număr înregistrare:" label-for="input-default">
                      <b-form-input  placeholder="J ..."></b-form-input>
                    </b-form-group>
                    <b-form-group label-cols="4" label-cols-lg="2" label="Sediul:" label-for="input-default">
                      <b-form-input v-model="company.address"></b-form-input>
                    </b-form-group>
                    <b-form-group label-cols="4" label-cols-lg="2" label="Administrator:" label-for="input-default">
                      <b-form-input ></b-form-input>
                    </b-form-group>
                    <b-form-group label-cols="4" label-cols-lg="2" label="Email facturare:" label-for="input-default">
                      <b-form-input ></b-form-input>
                    </b-form-group>

                    <b-button @click="updateCompany" class="pull-right ml-2 " size="sm" variant="success">Salvează</b-button>
              </b-tab>
              <b-tab v-if="false" title="Plan tarifar"  style="max-width: 1200px;">
                  <h6>Alege plan tarifar</h6>
                  <hr />
                  <b-row class="w-50">
                    <b-col>
                      Plan tarifar curent:
                    </b-col>
                    <b-col>
                      <ul class="no-style">
                        <li>
                          <h4>LIFE-TIME</h4>
                        </li>
                        <li>0,00 RON / lunar</li>
                        <li>șoferi nelimitat</li>
                        <li>
                          <strong>Data scadentă: --.--.----</strong>
                        </li>
                      </ul>
                    </b-col>
                  </b-row>
                  <b-row class="mt-5 w-50">
                    <b-col>
                      Balanță:
                    </b-col>
                    <b-col>
                      <ul class="no-style">
                        <li class="d-flex">
                          <h4>0,00 RON </h4>
                          <b-button variant="success" class="ml-3" size="sm">Adaugă fonduri</b-button>
                        </li>
                      </ul>
                    </b-col>
                  </b-row>
                  <b-row class="mt-5" align-content="center">
                    <b-col md="3">
                      Schimbă plan tarifar:
                    </b-col>
                    <b-col class="pl-0">
                      <b-row align-h="center" align-v="center">
                        <b-col>
                          <b-card style="opacity: 0.5; cursor: no-drop">
                            <h4>LIFE-TIME</h4>
                            <ul class="no-style">
                              <li>- Nelimitat număr șoferi</li>
                              <li>- Nelimitat număr comenzi</li>
                              <li>- Aplicație mobilă personalizată: Client</li>
                              <li>- Aplicație mobilă personalizată: Șofer</li>
                              <li>- Cont dezvoltator Google și Apple</li>
                            </ul>
                          </b-card>
                        </b-col>
                        <b-col>
                          <b-card>
                            <div class="d-flex align-items-center">
                              <h4>ABONAMENT</h4>
                              <b-button variant="success" class="ml-3" size="sm">Activează!</b-button>
                            </div>
                            <ul class="no-style">
                              <li>- 6€ / lunar per mașină</li>
                              <li>- Nelimitat număr comenzi</li>
                              <li>- Aplicație client: OGO Taxi</li>
                              <li>- Aplicație șofer: OGO Driver</li>
                              <li>- Cont dezvoltator OGO - Google și Apple</li>
                            </ul>
                          </b-card>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-tab>
              <b-tab title="Setări căutare">
                  <h6>Setări mod de lucru</h6>
                  <p>Adaugă intervale și condiții</p>
                 <Builder />
              </b-tab>
              <b-tab title="Taxe și comisioane" style="max-width: 1200px">
                  <h6>Setări taxe și comisioane</h6>
                  <p>Taxe și comisioane</p>
                 <Tarifs />
              </b-tab>
              <b-tab title="Dispetcerat" style="max-width: 1200px">
                <h6>Dispetcerat</h6>
                <CompanySettings></CompanySettings>
              </b-tab>
            </b-tabs>
          </b-col>
        </b-row>
      </b-container>
  </div>
</template>

<script>
import config from '@/config'
import Builder from '../builder/Builder.vue'
import Tarifs from '../tarif/Tarifs.vue'
import CompanySettings from '@/components/settings/CompanySettings.vue'

export default {
  name: 'General',
  components: { CompanySettings, Builder, Tarifs },
  mounted () {
    this.$axios({
      method: 'get',
      url: config.baseApiUrl + '/api/company/' + this.$store.getters['auth/loggedUser'].companies[0].id
    })
      .then((response) => {
        this.company = response.data
      })
  },
  data () {
    return {
      company: {
        name: null
      }
    }
  },
  computed: {
  },
  methods: {
    uploadLogo () {
      const file = document.querySelector('input[type=file]').files[0]
      const reader = new FileReader()

      reader.onloadend = () => {
        this.$axios({
          method: 'patch',
          url: config.baseApiUrl + '/api/company/' + this.$store.getters['auth/loggedUser'].companies[0].id,
          data: { logo: reader.result }
        })
          .then(() => {
            this.$toasted.success('Logo salvat!', {
              duration: 3000
            })
            this.company.logo = reader.result
          })
      }

      reader.readAsDataURL(file)
    },
    updateCompany () {
      this.$axios({
        method: 'patch',
        url: config.baseApiUrl + '/api/company/' + this.$store.getters['auth/loggedUser'].companies[0].id,
        data: this.company
      })
        .then(() => {
          this.$toasted.success('Salvat cu succes!', {
            duration: 3000
          })
        })
    }
  }
}
</script>

<style scoped>
  .table tr th,
  .table tr td {
    vertical-align: middle;
  }
</style>
