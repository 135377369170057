<template>
  <div>
    <b-card no-body class="p-1 mb-2">
      <b-input-group v-if="editable">
        <b-form-input v-model="item.name" placeholder="Nume tarif"></b-form-input>
        <b-form-input v-model="item.start_cost" placeholder="Cost pornire"></b-form-input>
        <b-form-input v-model="item.per_km_cost" placeholder="Per km"></b-form-input>
        <b-form-timepicker v-model="item.time_from" size="sm" locale="ro" placeholder="De la"></b-form-timepicker>
        <b-form-timepicker v-model="item.time_to" size="sm" locale="ro" placeholder="Până la"></b-form-timepicker>
        <b-input-group-append>
          <b-button size="sm" class="px-2" variant="danger" @click="deleteTarif"> <b-icon icon="x"></b-icon> </b-button>
          <b-button size="sm" class="px-2" variant="success" @click="updateTarif"> <b-icon icon="check"></b-icon> </b-button>
        </b-input-group-append>
      </b-input-group>
      <b-input-group v-else>
        <b-form-input disabled v-model="item.name" placeholder="Nume tarif"></b-form-input>
        <b-form-input disabled v-model="item.start_cost" placeholder="Cost pornire"></b-form-input>
        <b-form-input disabled v-model="item.per_km_cost" placeholder="Per km"></b-form-input>
        <b-form-timepicker disabled v-model="item.time_from" size="sm" locale="ro" placeholder="De la"></b-form-timepicker>
        <b-form-timepicker disabled v-model="item.time_to" size="sm" locale="ro" placeholder="Până la"></b-form-timepicker>
        <b-input-group-append>
          <b-button size="sm" class="px-2" variant="success" @click="editable = true"> <b-icon icon="pencil"></b-icon> </b-button>
        </b-input-group-append>
      </b-input-group>

    </b-card>

  </div>
</template>

<script>
import config from '@/config'

export default {
  name: 'TarifItem',
  data () {
    return {
      editable: false
    }
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    updateTarif () {
      this.$axios({
        method: 'patch',
        url: config.baseApiUrl + '/api/tarifs/' + this.item.id,
        data: this.item
      })
        .then((response) => {
          this.$toasted.success('Interval actualizat cu succes!', {
            duration: 3000
          })
          this.editable = false
        })
    },
    deleteTarif () {
      this.$axios({
        method: 'delete',
        url: config.baseApiUrl + '/api/tarifs/' + this.item.id,
        data: this.item
      })
        .then((response) => {
          this.$toasted.success('Interval șters cu succes!', {
            duration: 3000
          })
          this.$parent.getTarifs()
        })
    }
  }
}
</script>
