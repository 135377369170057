<script>
import config from '@/config'

export default {
  name: 'CompanySettings',
  created () {
    this.$axios.get(config.baseApiUrl + '/api/company-options')
      .then((response) => {
        this.options = response.data
      })
  },
  data () {
    return {
      options: null
    }
  },
  methods: {
    saveOptions () {
      this.$axios.patch(config.baseApiUrl + '/api/company-options', this.options)
        .then((response) => {
          this.options = response.data
        })
    }
  }
}
</script>

<template>
  <div>
    <b-form-checkbox
      v-if="options"
      v-model="options.value.sendOrderToOtherCompany"
      :value="true"
      :unchecked-value="false"
      @change="saveOptions"
    >
      Send order to other company
    </b-form-checkbox>
  </div>
</template>

<style scoped>

</style>
