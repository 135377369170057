<template>
  <div>
    <b-row>
      <b-col md="8">
        <TarifItem v-for="tarif in tarifs" :key="tarif.id" :item="tarif" />
        <b-card no-body class="p-1 mb-2">
          <b-input-group v-if="editableFields">
            <b-form-input v-model="tarifItem.name" placeholder="Nume tarif"></b-form-input>
            <b-form-input v-model="tarifItem.start_cost" placeholder="Cost pornire"></b-form-input>
            <b-form-input v-model="tarifItem.per_km_cost" placeholder="Per km"></b-form-input>
            <b-form-timepicker v-model="tarifItem.time_from" size="sm" locale="ro" placeholder="De la"></b-form-timepicker>
            <b-form-timepicker v-model="tarifItem.time_to" size="sm" locale="ro" placeholder="Până la"></b-form-timepicker>
            <b-input-group-append>
              <b-button size="sm" class="px-2" variant="danger" @click="editableFields = false"> <b-icon icon="x"></b-icon> </b-button>
              <b-button size="sm" class="px-2" variant="success" @click="addTarif"> <b-icon icon="check"></b-icon> </b-button>
            </b-input-group-append>
          </b-input-group>
          <b-button v-if="!editableFields" size="md" variant="outline-success" class="py-2 w-100"  @click="editableFields = true"> <b-icon icon="plus"></b-icon> Adaugă tarif nou</b-button>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import config from '@/config'
import TarifItem from './TarifItem.vue'

export default {
  name: 'Tarifs',
  components: { TarifItem },
  data () {
    return {
      tarifs: null,
      tarifItem: {
        name: null,
        type: 'time',
        start_cost: null,
        per_km_cost: null,
        time_from: null,
        time_to: null,
        company_id: this.$store.getters['auth/loggedUser'].companies[0].id
      },
      editableFields: false
    }
  },
  mounted () {
    this.getTarifs()
  },
  methods: {
    addTarif () {
      this.$axios({
        method: 'post',
        url: config.baseApiUrl + '/api/tarifs',
        data: this.tarifItem
      })
        .then(() => {
          this.getTarifs()
          this.editableFields = true
          this.$toasted.success('Tarif creat cu succes!', {
            duration: 3000
          })
        })
    },
    getTarifs () {
      this.$axios.get(config.baseApiUrl + '/api/tarifs')
        .then((response) => {
          this.tarifs = response.data.data
        })
    }
  }
}
</script>
